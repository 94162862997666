import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  private secretKey = 'fXr7!qZ@L9mNp3^dK%w6s+2bV&8xT$EoC*1Jg4aH$5#lY$%0-9vP!R@zU8Q^6i7yK$*xA$';  // Use a secure key
  private excludedPatterns = [
    "/api/v1/fallback",
    "/api/v1/cache",
    "/api/v1/test",
    "/api/v1/reports",
    "/api/v1/reports",
    "/api/v1/client/upload",
    "/api/v1/user/upload-csv-file",
    "/api/v1/reports/custom",
    "/api/v1/reports/download/co2/lifetime",
    "/api/v1/reports/download/gst/invoices/zip",
    "/api/v1/reports/download/payment/receipts/zip",
    "/api/v1/reports/download/transaction/csv",
    "/api/v1/shared/ride/bulk/request",
    "/api/v1/ride/receipt/download",
    "/api/v1/ride/invoice/download",
    "/api/v1/payments/initiate/razorpay/",
    "/api/v1/ride/all/rides/CSV/pastride",
    "/api/v1/payments/check/razorpay",
    "/api/v1/payments",
    "/api/v1/ride/receipt/download",
    "/api/v1/ride/invoice/download",
    "/api/v1/ride/all/rides/CSV/pastride",
    "/api/v1/view/web/strings?type=HOME_SCREEN",
    "/api/v1/country/list",
    "/api/v2/ride/all/rides/scheduled",
    "/api/v1/view/web/strings",
    "/api/v1/client/create",
    "/api/v1/support/ticket/save"
  ]

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const shouldExclude = this.excludedPatterns.some(path => req.url.includes(path));
    const isExcludedFromBE = localStorage.getItem("ishmacenabled") == "true";
  
    // If the request should be excluded, bypass the interceptor
    if (shouldExclude || !isExcludedFromBE) {
      return next.handle(req);
    }
    // Encrypt the request body if it exists
    let encryptedReq = req.clone();
    if (req.body&& (req.method == "POST" || req.method=="PUT")) {
      const header: any = {};
      for (const headerKey of req.headers.keys()) {
      header[headerKey] = req.headers.get(headerKey);
      }
      const encryptedBody = this.encrypt(req.body);
      header['hmacHash'] = encryptedBody;
      encryptedReq =  req.clone({
        headers: new HttpHeaders(header)
      });
    }

    return next.handle(encryptedReq).pipe(
      map(event => {
   
        // Decrypt the response if it is an HttpResponse
        if (event instanceof HttpResponse && event.body) {
          if(localStorage.getItem('ishmacenabled') == null && event.headers.get("ishmacenabled") == null)
            localStorage.setItem("ishmacenabled","false");
          if(event.headers.get("ishmacenabled") != null)
            localStorage.setItem("ishmacenabled",event.headers.get("ishmacenabled")+"");
  

          const decryptedBody =localStorage.getItem('ishmacenabled') == "true" ? this.decrypt(event.body) :event.body;
          // console.log(decryptedBody);
          return event.clone({ body: decryptedBody });
        }
        return event;
      })
    );
  }

  // Encryption method using HMAC
  private encrypt(data: any): string {
    const keyBytes = Buffer.from(this.secretKey, 'utf8');
    const hmac = this.calculateHMAC(data, this.secretKey);
   return hmac;
  }

  // Decryption method
  private decrypt(data: string): any {
    const combinedBytes = this.hexStringToByteArray(data['data']);
    const xorPayloadBytes = combinedBytes.slice(32);
    const decryptedPayloadBytes = this.xorWithKey(xorPayloadBytes, this.secretKey);
    const decryptedPayload = Buffer.from(decryptedPayloadBytes).toString('utf8');
    try {
      return JSON.parse(decryptedPayload);
  } catch (error) {
      return decryptedPayload;
  }
  }

  private calculateHMAC(data, secretKey) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(JSON.stringify(data), secretKey));
}
  private xorWithKey(data, key) {
    const keyBytes = Buffer.from(key, 'utf8');
    const result = Buffer.alloc(data.length);
    for (let i = 0; i < data.length; i++) {
        result[i] = data[i] ^ keyBytes[i % keyBytes.length];
    }
    return result;
}
private hexStringToByteArray(hex) {
    const bytes = [];
    for (let c = 0; c < hex.length; c += 2) {
        bytes.push(parseInt(hex.substr(c, 2), 16));
    }
    return Buffer.from(bytes);
}

private bytesToHex(bytes) {
    return Array.from(bytes)
        .map(byte => byte.toString().padStart(2, '0'))
        .join('');
}


}
