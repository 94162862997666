import { Injectable, NgZone } from '@angular/core';
import { CreateRideApiService } from 'src/app/app-v2/api-services/create-ride/create-ride-api.service';
import { CreateRideRequestApiDto } from 'src/app/app-v2/types/apis.type';
import { CreateRideService } from '../create-ride/create-ride-container.service';
import { PaymentDetailsContainerService } from '../create-ride/payment-details-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { PaymentDetailsApiService } from 'src/app/app-v2/api-services/create-ride/payment-details-api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTimePipe } from 'src/app/app-v2/pipe/createridepipe.pipe';
import { Theme } from 'src/app/app-v2/types/enums';

@Injectable({
  providedIn: 'root'
})
export class PaymentModesService {
  paymentFlowType = null;
  selectedPaymentMode:string;
  rideRequestId: number;


  razorPayOptions = {
    "key": environment.razorpayKey,
    "order_id": "", 
    "prefill": {"email": "", "contact": "" 
  },

  "config": {
    display: {
      blocks: {
        banks: {
          name: 'Pay via Card',
          instruments: [
            {
              method: 'card'
            }
          ],
        },
      },
      sequence: ['block.banks'],
      preferences: {
        show_default_blocks: false,
      },
    },
  }
};
razorpayNewWindow:Window;
razorpayOrderId: number;




  constructor(private _createRideApi: CreateRideApiService,
              private _createRide: CreateRideService,
              private _payment: PaymentDetailsContainerService,
              private _paymentApi: PaymentDetailsApiService,
              private _commonSubscription: CommonSubscriptionService,
              private _zone: NgZone,
              private _loader: NgxSpinnerService,
              private _dateTimePipe: DateTimePipe
  ) { }


  setSelectedPaymentMode(paymentMode, flowType){
    this.selectedPaymentMode = paymentMode;
    this.paymentFlowType = flowType;
    this.startSelectedPaymentFlow(paymentMode);
  }

  startSelectedPaymentFlow(paymentMode){
    switch(paymentMode){
      case 'CASH':
        this.startCashPaymentFlow();
        return;

      case 'BUSINESS_ACCOUNT':
        this.startBusinessWalletFlow();
        return;

      case 'PREPAID':
        this.startPrePaidFlow();
        return;

      case 'POSTPAID':
        this.startPostPaidFlow();
        return;

      case 'CREDIT_CARD':
        this.startCardFlow();
        return;

    }
  }


  sendRideRequest(){
    let x = new Promise((resolve, reject)=>{
      this._loader.show();
      this._createRideApi.requestRide(this._createRide.getCreateRideRequestApiDto()).subscribe(res=>{
        this._loader.hide();
        if(res['statusCode'] == 200){
          this._payment.setRideRequestDetails(res['response']);
          this.rideRequestId = res['response']['rideRequestId'];
          resolve(true);
        }
  
        else{
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: 'Technical Error!',
            message: res['message'],
            ctaLabel: 'OK!'
          });
          reject(res);
        }
      });
    })

    return x;
  }


  confirmAction(){
    let actionDto = {action : "CONFIRM"};
    let x = new Promise((resolve, reject)=>{
      this._loader.show()
      this._paymentApi.rideAction(this.rideRequestId, actionDto).subscribe(res=>{
        this._loader.hide();
        res['statusCode'] == 200 ? resolve(res) : reject(res);
      })
    });
    return x;
  }


  getCreditCardStatus(){
    let x  = new Promise((resolve, reject)=>{
      this._loader.show();
      this._paymentApi.getCreditCardStatus(this.razorpayOrderId).subscribe(res=>{
        this._loader.hide();
        res['statusCode'] == 200 ? resolve(res) : reject(res);
      });
    })
    return x;
  }

  getIsApplicableForPrepaid(){
    let x  = new Promise((resolve, reject)=>{
      this._loader.show()
      this._paymentApi.addMoneyToWalletFromTeller(this.rideRequestId).subscribe((res)=>{
        this._loader.hide()
        if(res['statusCode'] == 200){
          if(res['response']['isApplicable']){
            resolve(res);
          }
          else{
            reject(res);
          }
        }
        else{
          reject(res);
        }
      })
    });
    return x;
  }

  
  startCashPaymentFlow(){
    this.sendRideRequest().then(res=>{
      this.confirmAction().then(res=>{
        if(this.paymentFlowType == 'CREATE_RIDE'){
          this.printSuccessMessageForCreateRide();
        }
      })
      .catch(res=>{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'Ok!'
        });
      })
    });
    }
  
  startBusinessWalletFlow(){
    this.sendRideRequest().then(res=>{
      this.confirmAction().then(res=>{
        if(this.paymentFlowType == 'CREATE_RIDE'){
          this.printSuccessMessageForCreateRide();
        }
      })
      .catch(res=>{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'Ok!'
        });
      })
    });
  }

  startCardFlow(){
    this.sendRideRequest().then(res=>{
      this.confirmAction().then(res=>{
        this.razorpayOrderId = res['response']['paymentDetailsDto']['orderId']
        this.razorPayOptions.order_id = res['response']['paymentDetailsDto']['gatewayOrderId'];
        this.razorPayOptions['prefill']['contact'] = localStorage.getItem('phoneNo')
        this.razorPayOptions['prefill']['email'] = localStorage.getItem('email')
        this.razorPayOptions['handler'] = (res)=>setTimeout(()=>{this.razorpayHandler(res);}, 5000);
        this.razorpayNewWindow = window['Razorpay'](this.razorPayOptions).open()
      });
    });
  }

  razorpayHandler(handlerData){
    this._zone.run(() => {
      this.getCreditCardStatus().then(response => {
        this.confirmAction().then(res => {
          if (res['response']['paymentDetailsDto']) {
            this._commonSubscription.emitAlert({
              theme: Theme.WARNING,
              title: 'Technical Error',
              message: 'Your payment for this ride is currently being processed. If the transaction fails, the amount will be refunded within 7 working days',
              ctaLabel: 'Got it',
            });
          } else {
            if(this.paymentFlowType == 'CREATE_RIDE'){
              this.printSuccessMessageForCreateRide();
            }
          }
        }).catch(res => {
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: 'Technical Error!',
            message: res['message'],
            ctaLabel: 'Ok!'
          });
        });
      });
    });
  }


  startPostPaidFlow(){
    this.sendRideRequest().then(res=>{
      this.confirmAction().then(res=>{
        if(this.paymentFlowType == 'CREATE_RIDE'){
          this.printSuccessMessageForCreateRide();
        }
      })
      .catch(res=>{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'Ok!'
        });
      })
    });
  }


 startPrePaidFlow(){
    this.sendRideRequest().then(res=>{
      this.getIsApplicableForPrepaid().then(res=>{
        let paymentOrderId = res['response']['paymentOrderId'];
        this.tellerHandler(res['response']['redirectionUrl']).then(res=>{        
            this.checkStatusForPrepaidPayment(paymentOrderId).then(res=>{
              this.confirmAction().then(res=>{
                if(this.paymentFlowType == 'CREATE_RIDE'){
                  this.printSuccessMessageForCreateRide();
                }
              })
              .catch(res=>{
                this._commonSubscription.emitAlert({
                  theme: Theme.ERROR,
                  title: 'Technical Error!',
                  message: res['message'],
                  ctaLabel: 'Ok!'
                });
              })
            })
            .catch(res=>{
              this._commonSubscription.emitAlert({
                theme: Theme.WARNING,
                title: 'Info!',
                message: "Transaction is in Status: "+ res['response'],
                ctaLabel: 'Ok!'
              });
            })
        })
      })
      .catch(res=>{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'Ok!'
        });
      })
    })
  }


  tellerHandler(url){
    this._loader.show();
    let x  = new Promise((resolve, reject)=>{
      let newWindow = window.open(url,"Add Money","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=300,height=300,left = 490,top = 262");
      let windowInterval = setInterval(()=>{
        if(newWindow.closed){
          clearInterval(windowInterval);
          this._loader.hide();
          resolve(true);
        }
      },1000);
    });
    return x;
  }


  checkStatusForPrepaidPayment(id){
    this._loader.show();
    let x = new Promise((resolve, reject)=>{
      this._paymentApi.checkPrepaidStatus(id).subscribe(res=>{
        this._loader.hide();
          if(res['statusCode'] == 200){
            res['response'] == 'COMPLETED' ? resolve(res) : reject(res)
          }
          else{
            reject(res);
          }
      });
    });

    return x;
  }


  printSuccessMessageForCreateRide(){
    let name = this._payment.getRideRequestDetails().riderDetails['firstName'];
    let date = this._dateTimePipe.transform(this._payment.getRideRequestDetails().rideDetails.scheduleTimestamp).split(",")[0];
    let time = this._dateTimePipe.transform(this._payment.getRideRequestDetails().rideDetails.scheduleTimestamp).split(",")[1];

    this._commonSubscription.emitAlert({
      theme: Theme.SUCCESS,
      title: 'Booking Confirmed',
      message: `${name}'s cab is booked for ${date} at ${time}
                      have a safe journey with us`,
      ctaLabel: 'Got it',
    });
  }
}


