import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarType, CustomDropDownInputType, CustomInputType, FetchLandmarksApiResponse, PlacesInputType } from '../../types/components.type';
import { Dropdown } from 'primeng/dropdown';
import { CommonService } from '../../getter-setter/common.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Location } from '../../types/container.type';
import { HttpClient } from '@angular/common/http';
import { API_URL_LOCATION_METADATA } from 'src/app/config/apiRouteConfig';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { Theme } from '../../types/enums';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'custom-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class CustomInputComponent implements OnChanges, OnInit{

@ViewChild('countryDropDown') callingCodeDropdown: Dropdown;

// attributes , getting data from parent to child
@Input() inputBoxDetails: CustomInputType | CustomDropDownInputType | PlacesInputType | CalendarType
@Input() inputType: string
@Input() inputName: string

//custom events, sending data from child to parent
@Output() onSendInputData = new EventEmitter<string>();
@Output() onSendLocationData = new EventEmitter<Location>();
@Output() onSelectDate = new EventEmitter<Date>();
@Output() onDatePanelVisible = new EventEmitter<void>();
@Output() onClearInput = new EventEmitter<void>();

timeoutId;
prevLocation: {lat: number, lng: number, place: string} = {
  lat: 0,
  lng: 0,
  place: ''
};

constructor(private _common: CommonService, private _http: HttpClient, private _commonSubscription: CommonSubscriptionService, private _loader: NgxSpinnerService){

}

ngOnInit(): void {
}

ngOnChanges(changes: SimpleChanges): void {
}

clearInput(type){
  if(!this.inputBoxDetails.isDisabled){
    this.inputBoxDetails.inputValue = null;
    this.sendInputData(type);
    this.onClearInput.emit();
  }
}


clearPlacesInput(type){
  if(!this.inputBoxDetails.isDisabled){
  (this.inputBoxDetails as PlacesInputType).inputValue.lat = 0;
  (this.inputBoxDetails as PlacesInputType).inputValue.lng = 0;
  (this.inputBoxDetails as PlacesInputType).inputValue.place = null;
  (this.inputBoxDetails as PlacesInputType).landmarks = [];
  (this.inputBoxDetails as PlacesInputType).isLandmarkSelected = [];
  (this.inputBoxDetails as PlacesInputType).showLandmark = false;
  (this.inputBoxDetails as PlacesInputType).isExpressRide = false;
  (this.inputBoxDetails as PlacesInputType).isAirportLocation = false;
  this.onSendLocationData.emit((this.inputBoxDetails as PlacesInputType).inputValue);
  this.sendInputData(type);
  }
}

openDropDown(){
this.callingCodeDropdown.overlayVisible = !this.callingCodeDropdown.overlayVisible;
this.callingCodeDropdown.show();
}


sendInputData(type){
  clearTimeout(this.timeoutId); 
  this.timeoutId = setTimeout(()=>{ 
    this.onSendLocationData.emit((this.inputBoxDetails as PlacesInputType).inputValue);
      this.onSendInputData.emit(type);   
      return ; 
  } 
, 300)}




getLocation(address: Address){
  console.log(address.geometry.location, address.formatted_address);
(this.inputBoxDetails as PlacesInputType).inputValue.lat = address.geometry.location.lat();
(this.inputBoxDetails as PlacesInputType).inputValue.lng =  address.geometry.location.lng();
(this.inputBoxDetails as PlacesInputType).inputValue.place =  address.formatted_address;

this.prevLocation['lat'] = (this.inputBoxDetails as PlacesInputType).inputValue.lat;
this.prevLocation['lng'] = (this.inputBoxDetails as PlacesInputType).inputValue.lng;
this.prevLocation['place'] = (this.inputBoxDetails as PlacesInputType).inputValue.place;


if((this.inputBoxDetails as PlacesInputType).isLandmarkEnabled || (this.inputBoxDetails as PlacesInputType).isAirportEnabled){
  (this.inputBoxDetails as PlacesInputType).landmarks = [];
  this.fetchLandmarks();
}


else{
  this.onSendLocationData.emit((this.inputBoxDetails as PlacesInputType).inputValue);
}
}


selectLandmark(index){
  let prevState = (this.inputBoxDetails as PlacesInputType).isLandmarkSelected[index];
  this.clearIsLandmarkSelected();
  (this.inputBoxDetails as PlacesInputType).isLandmarkSelected[index] = !prevState;

  if((this.inputBoxDetails as PlacesInputType).isLandmarkSelected.includes(true)){
    (this.inputBoxDetails as PlacesInputType).inputValue.lat = (this.inputBoxDetails as PlacesInputType).landmarks[index].lat;
    (this.inputBoxDetails as PlacesInputType).inputValue.lng = (this.inputBoxDetails as PlacesInputType).landmarks[index].lng;
    (this.inputBoxDetails as PlacesInputType).inputValue.place = (this.inputBoxDetails as PlacesInputType).landmarks[index].place;
  }

  else{
    (this.inputBoxDetails as PlacesInputType).inputValue.lat = this.prevLocation.lat;
    (this.inputBoxDetails as PlacesInputType).inputValue.lng = this.prevLocation.lng;
    (this.inputBoxDetails as PlacesInputType).inputValue.place = this.prevLocation.place;
  }

  this.onSendLocationData.emit((this.inputBoxDetails as PlacesInputType).inputValue);

}


fetchLandmarks(){
let landmarkDto = {
  lat: (this.inputBoxDetails as PlacesInputType).inputValue.lat,
  lng: (this.inputBoxDetails as PlacesInputType).inputValue.lng,
  locationType: (this.inputBoxDetails as PlacesInputType).name
};

(this.inputBoxDetails as PlacesInputType).isLandmarkSelected = [];
      this._loader.show();
  this._http.post<FetchLandmarksApiResponse>(API_URL_LOCATION_METADATA, landmarkDto).subscribe(res=>{
    if(res['statusCode'] == 200){
      this._loader.hide();
      (this.inputBoxDetails as PlacesInputType).zoneId = res['response']['zoneId']

      if((this.inputBoxDetails as PlacesInputType).isLandmarkEnabled){
        if(res['response']['subPlacesDto']){
          res['response']['subPlacesDto']['subPlaces'].forEach(landmark=>{
            (this.inputBoxDetails as PlacesInputType).landmarks.push({
              lat: landmark.latitude,
              lng: landmark.longitude,
              place: landmark.displayName
            });
  
            (this.inputBoxDetails as PlacesInputType).isLandmarkSelected.push(false);
          })
        }
  
        (this.inputBoxDetails as PlacesInputType).showLandmark = (this.inputBoxDetails as PlacesInputType).landmarks.length > 0 ? true: false;
  
      }
  
      if((this.inputBoxDetails as PlacesInputType).isAirportEnabled){
        (this.inputBoxDetails as PlacesInputType).isAirportLocation = res['response']['is_airport'];
      }

      if((this.inputBoxDetails as PlacesInputType).isExpressRideEnabled){
        (this.inputBoxDetails as PlacesInputType).isExpressRide = res['response']['expressRide'];
      }

      this.onSendLocationData.emit((this.inputBoxDetails as PlacesInputType).inputValue);
    }

    else{
      this._commonSubscription.emitAlert({
        theme: Theme.ERROR,
        title: 'Technical Error!',
        message: res['message'],
        ctaLabel: 'Ok!'
      })
      this.clearPlacesInput('PLACES');
    }
  })
}


clearIsLandmarkSelected(){
  (this.inputBoxDetails as PlacesInputType).isLandmarkSelected = (this.inputBoxDetails as PlacesInputType).landmarks.map(landmark=>false)
}

selectDateHandler(){
  this.onSelectDate.emit((this.inputBoxDetails as CalendarType).inputValue);
}


datePanelVisible(){
  this.onDatePanelVisible.emit();
}
}
